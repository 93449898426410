import styled from "styled-components"
import theme from "../utils/theme"

export const ContentWrapper = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 90px 30px;

  > h1 {
    margin-top: 0;
  }
`

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: ${theme.screen.tablet}px) {
    display: block;
  }
`

export const Column = styled.div`
  flex: 1 1 auto;
  align-content: stretch;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
`
