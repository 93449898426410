export const formEncode = data =>
  Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")

export const observeHashChange = (action) => {
  if(typeof action === "function") {
    window.addEventListener("hashchange", action);
  }
}
