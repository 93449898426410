import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import theme from "../utils/theme"
import { observeHashChange } from "../utils/functions"

const HeaderWrapper = styled.header`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 10px 25px -15px #333;
`

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;

  &.wrapper {
    padding: 0;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  width: 400px;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;

  @media (max-width: ${theme.screen.narrow}px) {
    width: 300px;
  }

  @media (max-width: ${theme.screen.narrow}px) {
    width: 310px;
  }
`

const NavigationWrapper = styled.nav`
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);

  @media (max-width: ${theme.screen.tablet}px) {
    display: none;
  }
`

const NavigationUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

const NavigationLi = styled.li`
  display: inline-block;
`

const NavigationLink = styled.a`
  font-size: 18px;
  margin-left: 15px;

  @media (max-width: ${theme.screen.narrow}px) {
    font-size: 16px;
    margin-right: 8px;
  }
`

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo-horizontal.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    observeHashChange(() => {
      const hash = window.location.hash
      const element = document.getElementById(hash.replace("#", ""))
      if (element) {
        element.scrollIntoView()
      }
    })
  }, [])

  return (
    <HeaderWrapper>
      <ContentWrapper className="wrapper">
        <LogoWrapper>
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            alt="Pääkaupunkiseudun Tehohuolto Oy"
          />
        </LogoWrapper>
        <NavigationWrapper>
          <NavigationUl>
            <NavigationLi>
              <NavigationLink href="#etusivu">Etusivu</NavigationLink>
            </NavigationLi>
            <NavigationLi>
              <NavigationLink href="#palvelut">Palvelut</NavigationLink>
            </NavigationLi>
            <NavigationLi>
              <NavigationLink href="#logistiikka">Logistiikka</NavigationLink>
            </NavigationLi>
            <NavigationLi>
              <NavigationLink href="#yhteystiedot">Yhteystiedot</NavigationLink>
            </NavigationLi>
            <NavigationLi>
              <NavigationLink href="#ota-yhteytta">Ota yhteyttä</NavigationLink>
            </NavigationLi>
          </NavigationUl>
        </NavigationWrapper>
      </ContentWrapper>
    </HeaderWrapper>
  )
}

export default Header
