import { css } from "styled-components"
import theme from "./theme"

const Typography = css`
  html, body {
    font: 100%/1.618 ${theme.fonts.primary};
    font-weight: 300;
    color: ${theme.colors.primary};
    scroll-behavior: smooth;
  }
  
  .strong {
    font-weight: 800;
  }
  
  .lead {
    margin-top: 20px;
    margin-bottom: 0;
    + .lead {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  
  h1 {
    font-size: 28px;
    line-height: 1.69;
    font-weight: 900;
    margin: 10px auto 20px;
    text-align: center;
  }
  
  h2 {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0 auto 15px;
    text-transform: uppercase;
  }
  
  h4 {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 300;
    margin: 0 auto 15px;
  }
  
  a, a:visited, a:hover, a:focus, a:active {
    color: ${theme.colors.linkColor};
    text-decoration: none;
    font-weight: 400;
  }
  
  a:hover, a:focus {
    color: #c1cce2;
  }
  
  p {
    line-height: 1.5;
    margin-bottom: 10px;
    white-space: pre-line;
  }
  
  .alert {
    width: 100%;
    padding: 15px;
  
    li {
      line-height: 1.5;
    }
  
    &.alert-danger {
      background-color: ${theme.colors.danger};
      color: #fff;
    }
  }
  
  .hidden {
    display: none !important;
  }
`

export default Typography
