const theme = {
  colors: {
    primary: "#31383d",
    secondary: "#c9d0ea",
    tertiary: "#8a94b2",
    darkerGray: "#2a353b",
    darkerBlue: "#244672",
    danger: "#D84797",
    warning: "#EEE82C",
    linkColor: "#344870",
  },
  fonts: {
    primary: "Merriweather, serif",
  },
  screen: {
    desktop: 1440,
    narrow: 1024,
    desktopContent: 820,
    tablet: 768,
    mobile: 415,
    mobileXs: 380,
  },
}

export default theme
