import React from "react"
import PropTypes from "prop-types"

import styled, { ThemeProvider, createGlobalStyle } from "styled-components"

import Reset from "../utils/reset"
import Typography from "../utils/typography"
import theme from "../utils/theme"

import Header from "./header"

const GlobalStyles = createGlobalStyle`
  ${Reset}
  ${Typography}
`

const MainWrapper = styled.main`
  font-size: 14px;
`

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Header />
    <MainWrapper>{children}</MainWrapper>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
